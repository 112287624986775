<h3>Media list</h3>

<button (click)="fetchMediaItems()" nz-button nzType="primary">Refresh</button>
<div></div>
<ukata-media-type-picker
  (onMediaTypeChange)="updateAndFetchMedia($event)"
  [mediaType]="mediaType"
></ukata-media-type-picker>

<nz-table
  (nzQueryParams)="onPageChange($event)"
  [nzData]="mediaItems"
  [nzFrontPagination]="false"
  [nzLoading]="loadingService.loading$ | async"
  [nzPageIndex]="mediaItemPageIndex"
  [nzPageSizeOptions]="CommonService.pageSizeOptions"
  [nzPageSize]="mediaItemPageSize"
  [nzTotal]="totalMediaItems"
  nzShowSizeChanger
>
  <thead>
    <tr>
      <th>Media</th>
      <th>Actions</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let mediaItem of mediaItems">
      <td>
        {{ mediaItem.fileName }} |
        {{ mediaItem.createdDate | date: "YYYY-MMM-d H:mm:ss" }}
      </td>
      <td>
        <span *ngIf="mediaItem.type === 'IMAGE'"
          ><mat-icon color="accent">image</mat-icon></span
        >
        <span *ngIf="mediaItem.type === 'EXTERNAL_VIDEO'"
          ><mat-icon fontSet="material-symbols-outlined"
            >video_library</mat-icon
          ></span
        >
        <span *ngIf="mediaItem.type === 'AUDIO'"
          ><mat-icon fontSet="material-symbols-outlined"
            >audiotrack</mat-icon
          ></span
        >

        <button
          (click)="copyEmbedCode(mediaItem)"
          mat-icon-button
          matTooltip="get embed code"
        >
          <mat-icon fontSet="material-symbols-outlined">data_array</mat-icon>
        </button>
        <button
          (click)="downloadItem(mediaItem)"
          mat-icon-button
          matTooltip="download media"
        >
          <mat-icon fontSet="material-symbols-outlined">download</mat-icon>
        </button>
        <button
          (click)="previewMedia(mediaItem)"
          mat-icon-button
          matTooltip="preview media"
        >
          <mat-icon fontSet="material-symbols-outlined">visibility</mat-icon>
        </button>
      </td>
    </tr>
  </tbody>
</nz-table>
