<div class="container">
  <div class="row">
    <div class="col">
      <nz-form-item>
        <nz-form-label>Audio search by script</nz-form-label>
        <input
          (keyup.enter)="search()"
          [(ngModel)]="searchText"
          nz-input
          placeholder="Search by script"
          type="text"
        />
      </nz-form-item>
      <button (click)="search()" nz-button nzType="primary">Search</button>
    </div>
  </div>

  <div class="row" id="search-result">
    <div class="col">
      <nz-card *ngFor="let result of searchResults">
        <div [nz-tooltip]="result.transcript" class="search-result-item">
          {{ result.fileName }}
        </div>
        <div nz-row>
          <div nz-col>
            <ukata-download-media-button
              [mediaItem]="result"
            ></ukata-download-media-button>
          </div>
          <div nz-col>
            <ukata-view-media-button
              [mediaItem]="result"
            ></ukata-view-media-button>
          </div>
        </div>
      </nz-card>

      <nz-pagination
        (nzPageIndexChange)="search($event, pageSize)"
        (nzPageSizeChange)="search(pageIndex, $event)"
        [nzPageIndex]="pageIndex"
        [nzPageSizeOptions]="CommonService.pageSizeOptions"
        [nzPageSize]="pageSize"
        [nzTotal]="totalItems"
        aria-label="Select page"
      >
      </nz-pagination>
    </div>
  </div>
</div>
