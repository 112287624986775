<nz-form-item>
  <nz-form-label>{{ label }}</nz-form-label>
  <nz-select
    (ngModelChange)="updateStyle($event)"
    [(ngModel)]="style"
    nzAllowClear
    nzPlaceHolder="Select a style"
  >
    <nz-option
      *ngFor="let style of MakerCommonService.SpeechStyles"
      [nzLabel]="style"
      [nzValue]="style"
    ></nz-option>
  </nz-select>
</nz-form-item>
