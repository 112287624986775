<nz-tabset>
  <nz-tab nzTitle="Create audio">
    <ng-container *ngTemplateOutlet="mediaList"></ng-container>
  </nz-tab>
  <nz-tab nzTitle="Create conversation">
    <ng-container *ngTemplateOutlet="conversationList"></ng-container>
  </nz-tab>
  <nz-tab nzTitle="Upload">
    <ng-container *ngTemplateOutlet="uploadFile"></ng-container>
  </nz-tab>
  <nz-tab nzTitle="Audio search">
    <ng-container *ngTemplateOutlet="audioSearch"></ng-container>
  </nz-tab>
</nz-tabset>

<ng-template #mediaList matTabContent>
  <div class="tab-content">
    <mat-accordion>
      <ukata-create-narration></ukata-create-narration>
      <ukata-created-narration-content-list></ukata-created-narration-content-list>
    </mat-accordion>
  </div>
</ng-template>
<ng-template #uploadFile matTabContent>
  <div class="tab-content">
    <!--        Upload -->
    <input
      #fileUpload
      (change)="onFileSelected($event)"
      class="file-input"
      type="file"
    />

    <div>
      <label>Select file to upload</label>
    </div>
    <button (click)="fileUpload.click()" class="upload-btn" mat-icon-button>
      <mat-icon fontSet="material-symbols-outlined">attach_file</mat-icon>
    </button>

    <p></p>
    <div *ngIf="file">
      <mat-icon color="accent" style="scale: 0.7">attach_file</mat-icon>
      {{ file.name }}
    </div>

    <p></p>
    <nz-form-item>
      <nz-form-label>Title</nz-form-label>
      <input [(ngModel)]="title" nz-input />
    </nz-form-item>

    <div></div>
    <nz-form-item>
      <nz-form-label>Description</nz-form-label>
      <textarea [(ngModel)]="description" nz-input></textarea>
    </nz-form-item>

    <div></div>
    <label
      [(ngModel)]="hasTranscript"
      class="checkbox"
      nz-checkbox
      nzType="primary"
      >Has transcript
    </label>
    <label
      [(ngModel)]="hasSubtitles"
      class="checkbox"
      nz-checkbox
      nzType="primary"
      >Has subtitles
    </label>

    <div *ngIf="hasTranscript">
      <nz-form-item>
        <nz-form-label>Transcript</nz-form-label>
        <textarea
          [(ngModel)]="transcript"
          nz-input
          rows="3"
          style="width: 95%"
        ></textarea>
      </nz-form-item>
    </div>
    <p></p>
    <div *ngIf="hasSubtitles">
      <nz-form-item>
        <nz-form-label>Subtitle</nz-form-label>
        <textarea [(ngModel)]="subtitles" nz-input rows="3"></textarea>
      </nz-form-item>
    </div>
    <p></p>
    <button
      (click)="upload()"
      [disabled]="!file || (hasTranscript && !transcript)"
      nz-button
      nzType="primary"
    >
      Upload
    </button>
  </div>
</ng-template>
<ng-template #conversationList>
  <div class="tab-content">
    <ukata-create-view-conversation
      [showAsAccordion]="true"
    ></ukata-create-view-conversation>
  </div>
</ng-template>

<ng-template #audioSearch>
  <ukata-audio-search></ukata-audio-search>
</ng-template>
