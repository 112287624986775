<div *ngIf="mediaItem">
  <button nz-button nzShape="circle">
    <mat-icon
      (click)="loadAndViewMedia()"
      *ngIf="mediaItem.type === 'IMAGE'"
      color="primary"
      >image
    </mat-icon>
    <mat-icon
      (click)="loadAndViewMedia()"
      *ngIf="mediaItem.type === 'VIDEO'"
      color="primary"
      >video
    </mat-icon>
    <mat-icon
      (click)="loadAndViewMedia()"
      *ngIf="mediaItem.type === 'AUDIO'"
      color="primary"
      >music_note
    </mat-icon>
  </button>
</div>
