import { Component, OnInit } from '@angular/core';
import { CommonService } from '../../../core/services/common.service';
import { MediaItem } from '../../../shared/interfaces/media-item';
import { SingleValueResponse } from '../../../shared/interfaces/single-value-response';
import { LoadingService } from '../../../core/services/loading.service';
import { MakerMediaService } from '../../../core/services/maker-media.service';
import { NotificationService } from '../../../core/notifications/notification.service';
import { NzTableQueryParams } from 'ng-zorro-antd/table';

@Component({
  selector: 'ukata-created-narration-content-list',
  templateUrl: './created-narration-content-list.component.html',
  styleUrls: ['./created-narration-content-list.component.scss'],
})
export class CreatedNarrationContentListComponent implements OnInit {
  mediaType: string = 'AUDIO';
  mediaItems: MediaItem[] = [];
  mediaItemPageIndex: number = 1;
  mediaItemPageSize: number = 5;
  totalMediaItems: number = 0;
  mediaUrlMap = new Map<string, string>();
  protected readonly CommonService = CommonService;

  constructor(
    public loadingService: LoadingService,
    private makerMediaService: MakerMediaService,
    private notificationService: NotificationService,
  ) {}

  ngOnInit() {
    this.fetchMediaItems();
  }

  previewMedia(mediaItem: MediaItem) {
    this.makerMediaService.fetchMediaUrl(mediaItem.id).subscribe({
      next: (data: SingleValueResponse) => {
        this.mediaUrlMap.set(mediaItem.id, data.value);
      },
      error: (err) => {
        console.log('err', err);
      },
    });
  }

  public fetchMediaItems() {
    const index = this.mediaItemPageIndex - 1;
    this.makerMediaService
      .list(index, this.mediaItemPageSize, '', this.mediaType, false)
      .subscribe({
        next: (data) => {
          this.mediaItems = data.data;
          this.totalMediaItems = data.totalItems;
        },
        error: (error) => {
          console.log('list error error', error);
        },
      });
  }

  onPageChange($event: NzTableQueryParams) {
    this.mediaItemPageIndex = $event.pageIndex;
    this.mediaItemPageSize = $event.pageSize;
    this.fetchMediaItems();
  }

  copyEmbedCode(mediaItem: MediaItem) {
    let content = '';
    switch (mediaItem.type) {
      case 'VIDEO':
        content = `[uk-video name="${mediaItem.fileName}" mime="${mediaItem.mimeType}" src="${mediaItem.s3ObjectKey}"]`;
        break;
      case 'AUDIO':
        content = `[uk-audio name="${mediaItem.fileName}" mime="${mediaItem.mimeType}" src="${mediaItem.s3ObjectKey}"]`;
        break;
      case 'IMAGE':
        content = `[uk-image name="${mediaItem.fileName}" mime="${mediaItem.mimeType}" src="${mediaItem.s3ObjectKey}"]`;
        break;
      default:
        break;
    }

    if (content === '') {
      this.notificationService.error(
        'Embed code not available for this media item',
      );
      return;
    } else {
      CommonService.copyTextToClipboard(content);
      this.notificationService.success('Embed code copied to clipboard');
    }
  }

  updateAndFetchMedia(type: string) {
    this.mediaType = type;
    this.fetchMediaItems();
  }

  downloadItem(mediaItem: MediaItem) {
    CommonService.downloadMediaItem(
      1,
      mediaItem,
      this.makerMediaService,
      this.loadingService,
    );
  }
}
