<div>
  <h3>Create audio</h3>
  <nz-form-item>
    <nz-form-label>Media title</nz-form-label>
    <input [(ngModel)]="mediaTitle" nz-input />
  </nz-form-item>
  <nz-form-item>
    <nz-form-label>Media content</nz-form-label>
    <textarea [(ngModel)]="mediaContent" nz-input rows="5"></textarea>
  </nz-form-item>

  <ukata-tts-style-picker
    (onStyleChange)="style = $event"
    [style]="style"
  ></ukata-tts-style-picker>
  <ukata-tts-voice-picker
    (onVoiceChange)="voice = $event"
    [voice]="voice"
  ></ukata-tts-voice-picker>
  <p></p>

  <div>
    <div>
      <label [(ngModel)]="checkWithSTT" nz-checkbox>Check with STT</label>
      <label [(ngModel)]="fullCheck" [nzDisabled]="!checkWithSTT" nz-checkbox
        >Full check</label
      >
    </div>
    <div>
      <label [(ngModel)]="skipHashCheck" nz-checkbox
        >Skip check existing (hash)</label
      >
    </div>
  </div>
  <br />
  <button (click)="createNarrationAudio()" nz-button nzType="primary">
    Create
  </button>
</div>
