import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CoreModule } from '../../../core/core.module';
import { MakerMediaService } from '../../../core/services/maker-media.service';
import { LoadingService } from '../../../core/services/loading.service';
import { CommonService } from '../../../core/services/common.service';
import { MediaItem } from '../../../shared/interfaces/media-item';
import { ViewMediaButtonComponent } from '../common/view-media-button/view-media-button.component';
import { DownloadMediaButtonComponent } from '../common/download-media-button/download-media-button.component';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzPaginationModule } from 'ng-zorro-antd/pagination';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { SharedModule } from '../../../shared/shared.module';

@Component({
  selector: 'ukata-audio-search',
  standalone: true,
  imports: [
    CommonModule,
    CoreModule,
    ViewMediaButtonComponent,
    DownloadMediaButtonComponent,
    NzButtonModule,
    NzInputModule,
    NzCardModule,
    NzFormModule,
    NzPaginationModule,
    NzToolTipModule,
    SharedModule,
  ],
  templateUrl: './audio-search.component.html',
  styleUrl: './audio-search.component.scss',
})
export class AudioSearchComponent implements OnInit {
  searchText: string = '';
  searchResults: MediaItem[] = [];

  pageSize: number = 10;
  pageIndex: number = 1;
  totalItems: number = 0;
  protected readonly CommonService = CommonService;

  constructor(
    private makerMediaService: MakerMediaService,
    public loadingService: LoadingService,
    private cd: ChangeDetectorRef,
  ) {}

  ngOnInit() {}

  public search(pageIndex: number = 1, pageSize: number = 10) {
    this.resetPage();
    const index = pageIndex - 1;
    this.makerMediaService.search(this.searchText, index, pageSize).subscribe({
      next: (data: any) => {
        this.searchResults = data.data;
        this.totalItems = data.totalItems;
        this.cd.detectChanges();
      },
      error: (error) => {},
    });
  }

  private resetPage() {
    this.pageIndex = 1;
    this.pageSize = 10;
  }
}
