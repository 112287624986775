<h2>Conversations</h2>

<button (click)="resetEditingConversationPractice()" nz-button nzType="primary">
  Create new
</button>

<div *ngIf="showAsAccordion; else columnView">
  <ng-container *ngTemplateOutlet="accordionView"></ng-container>
</div>

<ng-template #columnView>
  <div id="layout-options">
    <!--  Create a radio group for user to select the layout of the items below -->
    <nz-radio-group (ngModelChange)="changeLayout($event)" [(ngModel)]="layout">
      <label nz-radio nzValue="created_top">Created list on top</label>
      <label nz-radio nzValue="created_below">Created list below</label>
      <label nz-radio nzValue="created_left">Created on the left</label>
      <label nz-radio nzValue="created_right">Created on the right</label>
      <label nz-radio nzValue="created_hidden">Created hidden</label>
    </nz-radio-group>
  </div>

  <div class="{{topClass}}">
    <div class="{{createdClass}}">
      <ukata-conversation-list
        (onConversationPracticeDelete)="fetchConversationPractices()"
        (onEditConversationPracticeChange)="editingConversationPractice=$event"
        [conversationPractices]="conversationPractices"
      ></ukata-conversation-list>
    </div>
    <div class="{{createPanelClass}}">
      <ukata-create-conversation
        (onEditConversationPracticeUpdated)="fetchConversationPractices()"
        [editingConversationPractice]="editingConversationPractice"
      ></ukata-create-conversation>
    </div>
  </div>
</ng-template>
<ng-template #accordionView>
  <mat-accordion>
    <mat-expansion-panel [expanded]="true">
      <mat-expansion-panel-header>Create</mat-expansion-panel-header>
      <ukata-create-conversation
        (onEditConversationPracticeUpdated)="fetchConversationPractices()"
        [editingConversationPractice]="editingConversationPractice"
      ></ukata-create-conversation>
    </mat-expansion-panel>

    <mat-expansion-panel>
      <mat-expansion-panel-header
        >Created conversations
      </mat-expansion-panel-header>
      <ukata-conversation-list
        (onConversationPracticeDelete)="fetchConversationPractices()"
        (onEditConversationPracticeChange)="editingConversationPractice=$event"
        [conversationPractices]="conversationPractices"
      ></ukata-conversation-list>
    </mat-expansion-panel>
  </mat-accordion>
</ng-template>
