import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ConversationPractice } from '../../../../interfaces/conversation-practice';
import { CommonService } from '../../../../../core/services/common.service';
import { LoadingService } from '../../../../../core/services/loading.service';
import { NotificationService } from '../../../../../core/notifications/notification.service';
import { ConversationPracticeService } from '../../../../services/conversation-practice.service';
import { MakerMediaService } from '../../../../../core/services/maker-media.service';
import { NzModalService } from 'ng-zorro-antd/modal';

@Component({
  selector: 'ukata-conversation-list',
  templateUrl: './conversation-list.component.html',
  styleUrls: ['./conversation-list.component.scss'],
})
export class ConversationListComponent {
  displayedColumns: string[] = ['title', 'details', 'actions'];
  @Input()
  conversationPractices: ConversationPractice[] = [];

  @Output()
  onEditConversationPracticeChange = new EventEmitter<ConversationPractice>();

  @Output()
  onConversationPracticeDelete = new EventEmitter<ConversationPractice>();
  pageIndex: number = 1;
  protected readonly CommonService = CommonService;

  constructor(
    public loadingService: LoadingService,
    private notificationService: NotificationService,
    private makerMediaService: MakerMediaService,
    private conversationPracticeService: ConversationPracticeService,
    private modalService: NzModalService,
  ) {}

  editPractice(cp: ConversationPractice) {
    this.onEditConversationPracticeChange.emit(cp);
  }

  deletePractice(cp: ConversationPractice) {
    this.deleteConversationPractice(cp);
  }

  previewConversation(
    conversation: ConversationPractice,
    showPlayerOnly: boolean = false,
  ) {
    //display a dialog to show the conversation audio
    alert('todo');
    // this.dialog.open(ConversationPreviewComponent, {
    //   data: {conversation, showPlayerOnly},
    // });
  }

  deleteConversationPractice(conversationPractice: ConversationPractice) {
    CommonService.showConfirmationDialog(
      this.modalService,
      'Delete?',
      'Are you sure you want to delete this conversation practice?',
      () => {
        this.callDeleteConversationPracticeApi(conversationPractice);
      },
    );
  }

  copyEmbedCode(cp: ConversationPractice) {
    const content = `[uk-conversation name="${cp.title}"  id="${cp.id}"]`;
    CommonService.copyTextToClipboard(content);
    this.notificationService.success('Embed code copied to clipboard');
  }

  downloadConversationAudio(cp: ConversationPractice) {
    CommonService.downloadMediaItemMultiple(
      cp.conversation!,
      this.makerMediaService,
      this.loadingService,
    );
  }

  private callDeleteConversationPracticeApi(
    conversationPractice: ConversationPractice,
  ) {
    this.conversationPracticeService
      .delete(conversationPractice.id!)
      .subscribe({
        next: (res) => {
          this.onConversationPracticeDelete.emit();
        },
        error: (err) => {
          console.log(err);
        },
      });
  }
}
