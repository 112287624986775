import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MediaItem } from '../../../../shared/interfaces/media-item';
import { CoreModule } from '../../../../core/core.module';
import { MakerMediaService } from '../../../../core/services/maker-media.service';
import { LoadingService } from '../../../../core/services/loading.service';
import { CommonService } from '../../../../core/services/common.service';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzWaveModule } from 'ng-zorro-antd/core/wave';

@Component({
  selector: 'ukata-view-media-button',
  standalone: true,
  imports: [CommonModule, CoreModule, NzButtonModule, NzWaveModule],
  templateUrl: './view-media-button.component.html',
  styleUrl: './view-media-button.component.scss',
})
export class ViewMediaButtonComponent {
  @Input()
  mediaItem: MediaItem | undefined;

  constructor(
    private makerMediaService: MakerMediaService,
    public loadingService: LoadingService,
  ) {}

  loadAndViewMedia() {
    this.makerMediaService.fetchMediaUrl(this.mediaItem?.id!).subscribe({
      next: (data: any) => {
        const url = data.value;
        if (this.mediaItem?.type === 'AUDIO') {
          CommonService.playAudioFromUrl(url);
        } else {
          window.open(url, '_blank');
        }
      },
      error: (error) => {},
    });
  }
}
