<div class="col-12" id="create-conversation-form">
  <!-- create conversation form -->

  <h3>
    {{ editingConversationPractice.id ? "Edit" : "Create new" }} conversation
  </h3>

  <nz-form-item>
    <nz-form-label>Title</nz-form-label>
    <input
      [(ngModel)]="editingConversationPractice.title"
      [required]="true"
      nz-input
    />
  </nz-form-item>
  <nz-form-item>
    <nz-form-label>Status</nz-form-label>

    <nz-select [(ngModel)]="editingConversationPractice.status" required>
      <nz-option
        *ngFor="let status of CommonService.MakerItemStatuses"
        [nzLabel]="status"
        [nzValue]="status"
      >
      </nz-option>
    </nz-select>
  </nz-form-item>

  <h3>Conversations</h3>
  <div class="row">
    <div class="col">
      <ukata-tts-voice-picker
        [label]="'Default voice'"
        (onVoiceChange)="defaultVoice = $event"
      ></ukata-tts-voice-picker>
    </div>
    <div class="col">
      <ukata-tts-style-picker
        (onStyleChange)="defaultStyle = $event"
      ></ukata-tts-style-picker>
    </div>
  </div>

  <nz-form-item>
    <nz-form-label>Raw conversation to parse</nz-form-label>
    <textarea
      [(ngModel)]="rawConversation"
      nz-input
      placeholder="speaker_id|style|text"
      rows="10"
    ></textarea>
  </nz-form-item>
  <button nz-button nzType="primary" (click)="parseConversation()">
    Parse
  </button>
  <button nz-button nzType="primary" (click)="applyDefaults()">
    Apply defaults
  </button>
  <div
    *ngIf="editingConversationPractice.speakers.length > 0"
    class="utility-buttons"
  >
    <div>
      <p>Reset all styles to</p>
      <ukata-tts-style-picker
        (onStyleChange)="populateStyle($event)"
      ></ukata-tts-style-picker>
    </div>

    <div>
      <div nz-row>
        <ukata-tts-voice-picker
          (onVoiceChange)="sourceVoice = $event"
          [voice]="sourceVoice"
        ></ukata-tts-voice-picker>
        <mat-icon fontSet="material-symbols-outlined">arrow_forward</mat-icon>
        <ukata-tts-voice-picker
          (onVoiceChange)="targetVoice = $event"
          [voice]="targetVoice"
        ></ukata-tts-voice-picker>
      </div>

      <button
        (click)="changeAllSourceToTargetVoice()"
        nz-button
        nzType="primary"
      >
        Switch voice
      </button>
    </div>

    <p></p>
    <button
      (click)="flipSpeaker(editingConversationPractice)"
      nzType="primary"
      nz-button
    >
      Flip
    </button>
  </div>
  <p></p>
  <p></p>
  <ul>
    <li
      *ngFor="let speaker of editingConversationPractice.speakers"
      class="single-speaker-item ukata-simple-box"
    >
      <div class="speaker-style">
        <ukata-tts-style-picker
          (onStyleChange)="speaker.style = $event"
          [style]="speaker.style!"
        ></ukata-tts-style-picker>
      </div>
      <div class="speaker-id">
        <ukata-tts-voice-picker
          (onVoiceChange)="speaker.speaker_id = $event"
          [voice]="speaker.speaker_id!"
        ></ukata-tts-voice-picker>
      </div>
      <nz-form-item>
        <nz-form-label>Text</nz-form-label>
        <input
          [(ngModel)]="speaker.text"
          [disabled]="editingConversationPractice.id != undefined"
          nz-input
        />
      </nz-form-item>
    </li>
  </ul>

  <ukata-loading-anim
    *ngIf="creatingOrUpdatingConversation"
    [bigBars]="false"
  ></ukata-loading-anim>
  <button
    (click)="createOrUpdate()"
    [disabled]="
      creatingOrUpdatingConversation || !editingConversationPractice.title
    "
    nz-button
    nzType="primary"
  >
    Save & create
  </button>
</div>
