import { Component, Inject } from '@angular/core';
import { ConfirmationData } from '../../../core/interfaces/confirmation-data';
import { NZ_MODAL_DATA } from 'ng-zorro-antd/modal';

@Component({
  selector: 'ukata-maker-tools',
  templateUrl: './maker-tools.component.html',
  styleUrls: ['./maker-tools.component.scss'],
})
export class MakerToolsComponent {
  constructor(@Inject(NZ_MODAL_DATA) public data: ConfirmationData) {}
}
