import { Component, Input, OnInit } from '@angular/core';
import { ConversationPracticeService } from '../../../services/conversation-practice.service';
import { ConversationPractice } from '../../../interfaces/conversation-practice';
import { LoadingService } from '../../../../core/services/loading.service';
import { NotificationService } from '../../../../core/notifications/notification.service';

@Component({
  selector: 'ukata-create-view-conversation',
  templateUrl: './create-view-conversations.html',
  styleUrls: ['./create-view-conversations.scss'],
})
export class CreateViewConversations implements OnInit {
  conversationPractices: ConversationPractice[] = [];
  pageIndex = 0;
  pageSize = 10;
  editingConversationPractice: ConversationPractice = {
    conversation: [],
    description: '',
    language: 'en',
    status: 'PUBLISHED',
    title: '',
    topic: '',
    level: 1,
    speakers: [],
  };

  layout: string = 'created_left';
  @Input()
  showAsAccordion: boolean = false;
  createdClass: string = 'col-md-5';
  createPanelClass: string = 'col-md-7';
  topClass: string = 'row flex-row';

  constructor(
    public loadingService: LoadingService,
    private notificationService: NotificationService,
    private conversationPracticeService: ConversationPracticeService,
  ) {}

  ngOnInit(): void {
    this.fetchConversationPractices();
  }

  public resetEditingConversationPractice() {
    this.editingConversationPractice = {
      conversation: [],
      description: '',
      language: 'en',
      status: 'DRAFT',
      title: '',
      topic: '',
      level: 1,
      speakers: [],
    };
  }

  public fetchConversationPractices() {
    this.conversationPracticeService
      .list('', 'en', [''], this.pageIndex, this.pageSize)
      .subscribe({
        next: (conversationPractices) => {
          this.conversationPractices = conversationPractices.data;
        },
        error: (err) => {
          console.log(err);
        },
      });
  }

  changeLayout($event: any) {
    switch ($event) {
      case 'created_top':
        this.createdClass = 'col-md-12';
        this.createPanelClass = 'col-md-12';
        this.topClass = 'row flex-row';
        break;
      case 'created_below':
        this.createdClass = 'col-md-12';
        this.createPanelClass = 'col-md-12';
        this.topClass = 'row flex-column-reverse';
        break;
      case 'created_left':
        this.createdClass = 'col-md-5';
        this.createPanelClass = 'col-md-7';
        this.topClass = 'row flex-row';
        break;
      case 'created_right':
        this.createdClass = 'col-md-5';
        this.createPanelClass = 'col-md-7';
        this.topClass = 'row flex-row-reverse';
        break;
      case 'created_hidden':
        this.createdClass = 'd-none';
        this.createPanelClass = 'col-md-12';
        this.topClass = 'row flex-row';
        break;

      default:
        this.createdClass = 'col-md-5';
        this.createPanelClass = 'col-md-7';
        this.topClass = 'row flex-row';
        break;
    }
  }
}
