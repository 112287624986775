import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { LoadingService } from '../../../core/services/loading.service';
import { MakerMediaService } from '../../../core/services/maker-media.service';
// @ts-ignore
import { v4 as uuid } from 'uuid';
import { CommonService } from '../../../core/services/common.service';
import { MediaItem } from '../../../shared/interfaces/media-item';
import { NotificationService } from '../../../core/notifications/notification.service';
import { MakerCommonService } from '../../services/maker-common.service';

@Component({
  selector: 'ukata-maker-sidebar',
  templateUrl: './maker-sidebar.component.html',
  styleUrls: ['./maker-sidebar.component.scss'],
})
export class MakerSidebarComponent implements OnInit {
  public readonly CommonService: typeof CommonService = CommonService;
  public readonly MakerCommonService: typeof MakerCommonService =
    MakerCommonService;
  uploading: boolean = false;
  hasTranscript: boolean = false;
  hasSubtitles: boolean = false;
  transcript: string = '';
  subtitles: string = '';

  mediaItems: MediaItem[] = [];
  title: string = '';
  description: string = '';

  file: File | undefined;
  style: string = 'default';
  //for media items

  mediaType: string = 'AUDIO';
  voice: string = '';

  constructor(
    public loadingService: LoadingService,
    private makerMediaService: MakerMediaService,
    private notificationService: NotificationService,
    private cd: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {}

  onFileSelected(event: any) {
    const file: File = event.target.files[0];

    if (file) {
      this.file = file;
    }
  }

  upload() {
    if (!this.file) {
      alert('Please select a file');
      return;
    }

    if (this.hasTranscript && this.transcript.trim().length === 0) {
      alert('Please enter a transcript');
      return;
    }
    this.uploading = true;
    const formData = new FormData();

    formData.append('file', this.file as File);
    formData.append('title', this.title);
    formData.append('description', this.description);

    //add transcript and subtitles
    this.hasTranscript && formData.append('transcript', this.transcript);
    this.hasSubtitles && formData.append('subtitles', this.subtitles);

    if (this.hasTranscript && this.transcript.trim().length === 0) {
      alert('Please enter a transcript');
      return;
    }

    if (this.hasSubtitles && this.subtitles.trim().length === 0) {
      alert('Please enter subtitles');
      return;
    }

    this.makerMediaService.upload(formData).subscribe({
      next: (data: MediaItem) => {
        this.mediaItems.push(data);
        this.uploading = false;
        this.file = undefined;
      },
      error: (error) => {},
    });
  }
}
