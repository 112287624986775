import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MakerCommonService } from '../../services/maker-common.service';

@Component({
  selector: 'ukata-tts-style-picker',
  templateUrl: './tts-style-picker.component.html',
  styleUrls: ['./tts-style-picker.component.scss'],
})
export class TtsStylePickerComponent {
  @Input()
  style: string = '';
  @Input()
  label: string = 'Voice style';

  @Output()
  onStyleChange = new EventEmitter<string>();
  protected readonly MakerCommonService = MakerCommonService;

  constructor() {}

  updateStyle($event: string) {
    this.style = $event;
    console.log('update style to -> ', this.style);
    this.onStyleChange.emit(this.style);
  }
}
