import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MediaItem } from '../../../../shared/interfaces/media-item';
import { MakerMediaService } from '../../../../core/services/maker-media.service';
import { LoadingService } from '../../../../core/services/loading.service';
import { CommonService } from '../../../../core/services/common.service';
import { NzButtonModule } from 'ng-zorro-antd/button';

@Component({
  selector: 'ukata-download-media-button',
  standalone: true,
  imports: [CommonModule, MatButtonModule, MatIconModule, NzButtonModule],
  templateUrl: './download-media-button.component.html',
  styleUrl: './download-media-button.component.scss',
})
export class DownloadMediaButtonComponent {
  @Input()
  mediaItem: MediaItem | undefined;

  public isDownloading: boolean = false;

  constructor(
    private makerMediaService: MakerMediaService,
    public loadingService: LoadingService,
  ) {}

  downloadItem() {
    if (!this.mediaItem) return;

    this.isDownloading = true;
    CommonService.downloadMediaItemObservable(
      this.mediaItem,
      this.makerMediaService,
    ).subscribe({
      next: (data: any) => {
        if (!data) {
          return;
        }

        this.isDownloading = false;
        CommonService.createLinkAndDownload(data, 1, this.mediaItem!);
      },
      error: (error) => {
        this.isDownloading = false;
      },
    });
    CommonService.downloadMediaItem(
      1,
      this.mediaItem,
      this.makerMediaService,
      this.loadingService,
    );
  }
}
