<div class="col-12" id="created-items">
  <!-- created items -->
  <h3>Created conversations</h3>

  <nz-table
    [nzData]="conversationPractices"
    [nzFrontPagination]="false"
    [nzLoading]="loadingService.loading$ | async"
    [nzPageSizeOptions]="CommonService.pageSizeOptions"
  >
    <thead>
      <tr>
        <th>Title</th>
        <th>Details</th>
        <th>Action</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let cv of conversationPractices">
        <td>{{ cv.title }}</td>
        <td>
          {{ cv.description }}
          {{ cv.level }}
          {{ cv.status }}
          {{ cv.topic }}
        </td>
        <td>
          <mat-icon
            (click)="editPractice(cv)"
            color="primary"
            fontSet="material-symbols-outlined"
            >edit
          </mat-icon>
          <mat-icon
            (click)="deletePractice(cv)"
            color="warn"
            fontSet="material-symbols-outlined"
            >delete
          </mat-icon>
          <mat-icon
            (click)="downloadConversationAudio(cv)"
            fontSet="material-symbols-outlined"
            >download
          </mat-icon>
          <mat-icon
            (click)="copyEmbedCode(cv)"
            fontSet="material-symbols-outlined"
            >data_array
          </mat-icon>
        </td>
      </tr>
    </tbody>
  </nz-table>
</div>
