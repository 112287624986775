<div id="words-extractor">
  <nz-form-item>
    <nz-form-label>Raw text</nz-form-label>
    <textarea
      [(ngModel)]="rawText"
      nz-input
      placeholder="put raw text here"
      rows="7"
    ></textarea>
  </nz-form-item>

  <label [(ngModel)]="lowerCase" nz-checkbox>To lower case</label>
  <mat-slider
    [displayWith]="formatLabel"
    [showTickMarks]="true"
    discrete
    max="10"
    min="0"
    step="1"
  >
    <input [(ngModel)]="minCharCount" matSliderThumb />
  </mat-slider>

  <button (click)="extractWords()" nz-button nzType="primary">Extract</button>
  <mat-divider></mat-divider>
  <nz-form-item>
    <nz-form-label>Parsed</nz-form-label>
    <textarea
      [value]="words.join('\n')"
      disabled
      nz-input
      placeholder="Result here"
      rows="7"
    ></textarea>
  </nz-form-item>

  <button (click)="copyToClipboard()" nz-button nzType="primary">
    Copy extracted text
  </button>
</div>
