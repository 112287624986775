import { Component } from '@angular/core';
import { CommonService } from '../../../../core/services/common.service';

@Component({
  selector: 'ukata-words-extractor',
  templateUrl: './words-extractor.component.html',
  styleUrls: ['./words-extractor.component.scss'],
})
export class WordsExtractorComponent {
  lowerCase: boolean = true;
  minCharCount: number = 2;
  rawText: string = '';
  words: string[] = [];

  constructor() {}

  extractWords(): void {
    this.words = CommonService.extractEnglishWords(
      this.rawText,
      this.lowerCase,
      this.minCharCount,
    );
  }

  copyToClipboard() {
    CommonService.copyTextToClipboard(this.wordsToText(this.words));
  }

  formatLabel(value: number): string {
    return `${value}`;
  }

  private wordsToText(words: string[]): string {
    return words.join('\n');
  }
}
